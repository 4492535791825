exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-blog-my-first-post-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/blog/my-first-post/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-blog-my-first-post-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-blog-my-second-post-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/blog/my-second-post/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-blog-my-second-post-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-play-js": () => import("./../../../src/pages/play.js" /* webpackChunkName: "component---src-pages-play-js" */),
  "component---src-pages-work-atlas-ai-aperture-js": () => import("./../../../src/pages/work/atlas-ai-aperture.js" /* webpackChunkName: "component---src-pages-work-atlas-ai-aperture-js" */),
  "component---src-pages-work-kayak-search-js": () => import("./../../../src/pages/work/kayak-search.js" /* webpackChunkName: "component---src-pages-work-kayak-search-js" */),
  "component---src-pages-year-2025-js": () => import("./../../../src/pages/year/2025.js" /* webpackChunkName: "component---src-pages-year-2025-js" */)
}

